import JSXAttribute from './JSXAttribute.js';
import JSXClosingElement from './JSXClosingElement.js';
import JSXClosingFragment from './JSXClosingFragment.js';
import JSXElement from './JSXElement.js';
import JSXExpressionContainer from './JSXExpressionContainer.js';
import JSXFragment from './JSXFragment.js';
import JSXOpeningElement from './JSXOpeningElement.js';
import JSXOpeningFragment from './JSXOpeningFragment.js';
import JSXSpreadAttribute from './JSXSpreadAttribute.js';

export default {
	JSXAttribute,
	JSXClosingElement,
	JSXClosingFragment,
	JSXElement,
	JSXExpressionContainer,
	JSXFragment,
	JSXOpeningElement,
	JSXOpeningFragment,
	JSXSpreadAttribute,
};
